@import '~react-image-gallery/styles/css/image-gallery.css';

.image-gallery {
  height: 100%;
  width: 100%;
  background: white;
}

.image-gallery.fullscreen-modal {
  z-index: 15;
}

.image-gallery.fullscreen-modal .image-gallery-fullscreen-button {
  right: 30px;
}

.image-gallery.fullscreen-modal .image-gallery-fullscreen-button svg {
  width: 30px;
  height: 30px;
}

.image-gallery:hover .image-gallery-fullscreen-button {
  opacity: 1;
}

.image-gallery-content,
.image-gallery-slide-wrapper,
.image-gallery-swipe {
  height: 100%;
}

.image-gallery-slides {
  height: 100%;
  display: flex;
  align-items: center;
}

.image-gallery-slide {
  display: flex;
  align-items: center;
  background: white;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  height: 100%;
}

.image-gallery-slide img {
  width: auto;
}

.image-gallery-slide.center,
.image-gallery-slide.left,
.image-gallery-slide.right {
  height: 100%;
}

.image-gallery-icon {
  color: var(--chakra-colors-gray-700);
  filter: none;
  transform: translateY(0);
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  margin: 4px;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  top: auto;
  bottom: 0;
  padding: 5px;
}

.image-gallery-fullscreen-button {
  right: 28px;
  top: 0;
  bottom: auto;
  opacity: 0;
  transition: all 300ms;
  transform: translateX(50%);
  padding: 10px;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 38px;
  width: 38px;
}

.image-gallery-icon:hover {
  filter: none;
  color: var(--chakra-colors-gray-900);
  transition: all 300ms;
}

.image-gallery-icon:hover .image-gallery-svg {
  transition: all 300ms;
}

.image-gallery-bullets .image-gallery-bullet {
  background: var(--chakra-colors-secondary-500);
  border: none;
  filter: none;
  box-shadow: none;
  opacity: 0.3;
}

.image-gallery-bullets .image-gallery-bullet:hover,
.image-gallery-bullets .image-gallery-bullet.active:hover {
  background: var(--chakra-colors-secondary-500);
  border: none;
  filter: none;
  box-shadow: none;
  opacity: 1;
}

.image-gallery-bullets .image-gallery-bullet.active {
  background: var(--chakra-colors-secondary-500);
  filter: none;
  box-shadow: none;
  opacity: 1;
  border: none;
}
