figure.table {
  width: 100% !important;
}

table {
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-collapse: collapse;
  border-left-style: solid;
  border-left-width: 0px;
  border-right-style: solid;
  border-right-width: 0px;
  border-top-style: solid;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(70, 72, 74);

  line-height: 24px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
  width: 100% !important;
}

table thead {
  background-color: rgb(235, 237, 239);
  border-bottom-color: rgb(30, 80, 130);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-collapse: collapse;
  border-left-style: solid;
  border-left-width: 0px;
  border-right-style: solid;
  border-right-width: 0px;
  border-top-style: solid;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(70, 72, 74);

  height: 79px;
  line-height: 24px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
}

table thead tr {
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-collapse: collapse;
  border-left-style: solid;
  border-left-width: 0px;
  border-right-style: solid;
  border-right-width: 0px;
  border-top-style: solid;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(70, 72, 74);
  line-height: 24px;
  overflow-wrap: break-word;
  text-rendering: optimizelegibility;
}

table thead tr th {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-collapse: collapse;
  border-left-style: solid;
  border-left-width: 0px;
  border-right-style: solid;
  border-right-width: 0px;
  border-top-style: solid;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(0, 0, 0);

  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.7px;
  line-height: 16px;
  overflow-wrap: break-word;
  padding-inline-end: 8px;
  padding-inline-start: 8px;
  text-align: left;
  text-rendering: optimizelegibility;
  text-transform: none;
}

table tbody {
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-collapse: collapse;
  border-left-style: solid;
  border-left-width: 0px;
  border-right-style: solid;
  border-right-width: 0px;
  border-top-style: solid;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgb(70, 72, 74);
}

table tbody tr {
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-collapse: collapse;
  border-left-style: solid;
  border-left-width: 0px;
  border-right-style: solid;
  border-right-width: 0px;
  border-top-style: solid;
  border-top-width: 0px;
  box-sizing: border-box;

  line-height: 24px;
  overflow-wrap: break-word;
  position: relative;
  text-rendering: optimizelegibility;
}

table tbody tr th {
  border-bottom-style: solid;
  border-bottom-width: 0px;
  border-collapse: collapse;
  border-left-style: solid;
  border-left-width: 0px;
  border-right-style: solid;
  border-right-width: 1px;
  border-top-style: solid;
  border-top-width: 0px;
  border-color: black;
  box-sizing: border-box;
  color: rgb(0, 0, 0);

  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.7px;
  line-height: 16px;
  overflow-wrap: break-word;
  padding-inline-end: 8px;
  padding-inline-start: 8px;
  text-align: left;
  text-rendering: optimizelegibility;
  text-transform: none;
}

table tbody tr:nth-child(odd) {
  background-color: #eff1f3;
}

table tbody tr:hover {
  background-color: #f9fbea;
}

ul {
  list-style-type: initial;
  list-style-position: inside;
}

ol {
  list-style-type: decimal;
  list-style-position: inside;
}
